<template>
	<div class="storeSet el-content">
		<a-tabs v-model:activeKey="activeName">
			<a-tab-pane key="first" tab="基本信息">
				<com-power-form :form-data="[
					{label:'是否开启多商户',type:'radio',value:'',key:'store_is_open',dataType:'string',
						options:[ {label:'是',value:'1'}, {label:'否',value:'0'}, ]
					},
					{label:'是否展示手机号',type:'radio',value:'',key:'open_shop_phone',dataType:'string',
						options:[ {label:'是',value:'1'}, {label:'否',value:'0'}, ]
					},
					{label:'是否开启免审核',type:'radio',value:'',key:'store_avoid_check',dataType:'string',
						options:[ {label:'是',value:'1'}, {label:'否',value:'0'}],
						tips:'开启后,用户提交申请入驻信息后,立即成为商户'
					},
					{label:'是否允许商户自主发布商品',type:'radio',value:'',key:'store_is_publish_goods',dataType:'string',
						options:[ {label:'是',value:'1'}, {label:'否',value:'0'}],
					},
					{label:'商品免审',type:'radio',value:'',key:'shop_goods_not_audit',dataType:'string',
						options:[ {label:'开启',value:'1'}, {label:'关闭',value:'0'}],
					},
					{label:'活动免审核',type:'radio',value:'',key:'shop_event_not_audit',dataType:'string',
						options:[ {label:'开启',value:'1'}, {label:'关闭',value:'0'}],
					},
					{label:'认养免审核',type:'radio',value:'',key:'shop_adopt_not_audit',dataType:'string',
						options:[ {label:'开启',value:'1'}, {label:'关闭',value:'0'}],
					},
					{label:'土地免审核',type:'radio',value:'',key:'shop_land_not_audit',dataType:'string',
						options:[ {label:'开启',value:'1'}, {label:'关闭',value:'0'}],
					},
					{label:'监控免审核',type:'radio',value:'',key:'shop_live_not_audit',dataType:'string',
						options:[ {label:'开启',value:'1'}, {label:'关闭',value:'0'}],
					},
					{label:'提现方式',type:'checkbox',value:'',key:'store_withdraw_method',dataType:'string',
						options:[ {label:'微信',value:'1'}, {label:'支付宝',value:'2'},{label:'余额',value:'3'}],
					},
					{label:'商户交易手续费',type:'text',value:'',key:'many_shop_trade_fee',dataType:'string',addon:'%'},
					{label:'商户等级计算方式',type:'radio',value:'',key:'many_shop_level_type',dataType:'string',
						options:[ {label:'销售量',value:'0'}, {label:'销售额',value:'1'},{label:'店铺评分',value:'3'}],
					},
					
				]"></com-power-form>
				
			</a-tab-pane>
			<a-tab-pane key="second" tab="入驻设置">
				<com-power-form :form-data="[
					{label:'是否显示入驻申请协议',type:'radio',value:'',key:'store_is_show_agreement',dataType:'string',
						options:[ {label:'是',value:'1'}, {label:'否',value:'0'}, ]
					},
					{label:'入驻宣传图片',type:'image',value:'',key:'store_head_img',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
			<a-tab-pane key="third" tab="商家协议">
					<com-power-form :form-data="[
					{label:'入驻申请协议',type:'editor',value:'',key:'store_apply_agreement',dataType:'string'},
				]"></com-power-form>
			</a-tab-pane>
			<a-tab-pane key="four" tab="农场中心">
				<div class="store-set-center" v-if="page_store_center">
					<div class="ssc-phone">
						<div class="ssc-header">
							<img class="ssc-header-logo" src="https://kundian.cqkundian.com/farm/1/png/20200803/1a89df2f2f85ac4d5647c110bc25d09b1596445317.png" alt="">
							<div class="ssc-header-right">
								<div class="sschr-name">
									智慧农场
								</div>
								<div class="sschr-desc">店铺描述描述</div>
								<div class="sschr-score">
									评分
								</div>
							</div>
						</div>
						<div class="ssc-renzhen" :class="page_current=='cert' ? 'scc-acitve':''" v-if="page_store_center.cert.show" @click="page_current ='cert'">
							<span>资质认证</span>
							<i class="ri-arrow-right-s-line"></i>	
						</div>
						<div v-for="(val,ind) in page_store_center.gird" :key="ind">
							<div class="scc-order" :class="page_current==ind ? 'scc-acitve':''" v-if="val.show" @click="page_current =ind">
								<div class="scc-order-title">{{val.title}}</div>
								<div class="scc-order-cont">
									<template  v-for="(item,index) in val.list" >
										<div class="scc-oc-item" :key="index" v-if="item.show">
											<img :src="item.icon" alt="">
											<div>{{item.name}}</div>
										</div>
									</template>
								</div>
							</div>
						</div>
						
						<div class="scc-order" :class="page_current=='stat' ? 'scc-acitve':''" v-if="page_store_center.stat.show" @click="page_current ='stat'">
							<div class="scc-order-title">{{page_store_center.stat.title}}</div>
							<div class="scc-data-cont">
								<template v-for="(item,index) in page_store_center.stat.list">
									<div class="scc-dc-item" :key="index" v-if="item.show">
										<div>{{item.value}}</div>
										<div>{{item.name}}</div>
									</div>
								</template>
							</div>
						</div>
					</div>
					<div class="ssc-right">
						<div class="scc-right-tag">
							<div :class="page_current == 'cert' ? 'scc-rt-active':''" @click="page_current = 'cert'">{{page_store_center.cert.title}}</div>
							<div :class="page_current == index ? 'scc-rt-active':''" @click="page_current = index" v-for="(item,index) in page_store_center.gird" :key="index">{{item.title}}</div>
							<div :class="page_current == 'stat' ? 'scc-rt-active':''" @click="page_current = 'stat'">{{page_store_center.stat.title}}</div>
						</div>
						
						<div class="ssc-right-info" v-if="page_current == 'cert'">
							<h4>资质认证</h4>
							<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
								<a-form-item label="是否展示">
									<a-input v-model:value="page_store_center.cert.title"></a-input>
								</a-form-item>
								<a-form-item label="是否展示">
									<a-switch v-model:checked="page_store_center.cert.show" ></a-switch>
								</a-form-item>
							</a-form>
						</div>
						<template v-for="(val,ind) in page_store_center.gird">
							<div class="ssc-right-info"  :key="ind" v-if="page_current == ind">
								<h4>{{val.title}}</h4>
								<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
									<a-form-item label="标题">
										<a-input v-model:value="val.title"></a-input>
									</a-form-item>
									<a-form-item label="是否展示">
										<a-switch v-model:checked="val.show" ></a-switch>
									</a-form-item>
									
									<div class="ssc-ri-item" v-for="(item,index) in val.list" :key="index">
										<a-form-item label="标题">
											<a-input v-model:value="item.name" class="ssc-riir-input"></a-input>
										</a-form-item>
										<a-form-item label="图标">
											<kd-img-select :show-img="false" :src="item.icon" @change="(url)=>{ item.icon = url }"></kd-img-select>
										</a-form-item>
										<a-form-item label="是否展示">
											<a-switch v-model:checked="item.show" ></a-switch>
										</a-form-item>
									</div>
								</a-form>
							</div>
						</template>
						
						<div class="ssc-right-info" v-if="page_current == 'stat'">
							<h4>{{page_store_center.stat.title}}</h4>
							<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
								<a-form-item label="标题">
									<a-input v-model:value="page_store_center.stat.title"></a-input>
								</a-form-item>
								<a-form-item label="是否展示">
									<a-switch v-model:checked="page_store_center.stat.show" ></a-switch>
								</a-form-item>
								<div class="ssc-ri-item" v-for="(item,index) in page_store_center.stat.list" :key="index">
									<a-form-item label="标题">
										<a-input v-model:value="item.name" class="ssc-riir-input"></a-input>
									</a-form-item>
									<a-form-item label="是否展示">
										<a-switch v-model:checked="item.show"></a-switch>
									</a-form-item>
								</div>
							</a-form>
						</div>
					</div>
				</div>
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button type="primary" @click="anewPageData" v-if="activeName == 'four'">重新载入数据</a-button>
				</a-space>
			</a-tab-pane>
		</a-tabs>	
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
import set from '@/api/set.js'
import customPage from '@/api/customPage.js'
import tool from '@/util/tool.js'
import { reactive, toRefs } from 'vue'
export default{
	components:{
		comPowerForm
	},
	setup(){
		const _d = reactive({
			activeName:'first',
			form:{
				store_center_page:{},
			},
			page_current:'',
			page_store_center:null,
		})

		set.getFarmSet(Object.keys(_d.form)).then(res=>{
			_d.form.store_center_page = res.store_center_page
			if( typeof _d.form.store_center_page == 'string'){
				_d.page_store_center = JSON.parse(_d.form.store_center_page)
				console.log('_d',_d.page_store_center);
				
			}
			if( !_d.page_store_center ){
				console.log('www');
				
				_d.page_store_center =customPage.getStoreCenterPage()
			}
		})

		function submitSave(){
			_d.form.store_center_page = JSON.stringify(_d.page_store_center)
			set.setFarmSet(_d.form)
		}
		function anewPageData(){
			tool.confirm("确认重新载入设置页面数据吗？点击确定后，请重新保存一下数据可生效").then(()=>{
				_d.page_store_center = customPage.getStoreCenterPage()
			}).catch(()=>{})
		}

		return{
			...toRefs(_d),
			submitSave,
			anewPageData
		}
	}
}
</script>

<style lang="scss">
.store-set-center{
	display: flex;
	
	.ssc-phone{
		width: 375px;
		height: 812px;
		border: 1px solid #e6e6e6;
		background: #f4f4f4;
		
		.ssc-header{
			width: 85%;
			margin-left: 4%;
			display: flex;
			color: #000;
			z-index: 999;
			position: relative;
			height: 95px;
			padding-top: 15px;
			
			&-logo{
				width: 70px;
				height: 70px;
				border-radius: 4px;
				margin-right: 10px;
			}
			
			&-right{
				width: 73%;
				
				.sschr-name{
					width: 100%;
					display: flex;
					justify-content: flex-start;
					
					text{
						font-size: 17px;
						font-weight: bold;
					}
				}
				.sschr-desc,.sschr-score{
					font-size: 12px;
					margin-top: 5px;
					color: #999;
				}
				.sschr-score{
					display: flex;
					justify-content: flex-start;
					
					.icon-shoucang{
						color: #FFBF00;
						margin: 0 1px;
						font-size: 15px;
					}
				}
			}
		}
		
		.ssc-renzhen{
			width: 90%;
			padding:12px 2%;
			margin-left: 3%;
			background: #fff;
			border-radius: 8px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			span{
				font-weight: bold;
				font-size: 15px;
			}
		}
		
		.scc-order{
			width: 90%;
			padding:12px 2%;
			margin-left: 3%;
			background: #fff;
			border-radius: 8px;
			margin-top: 16px;
			cursor: pointer;
			
			&-title{
				width: 100%;
				height: 32px;
				line-height: 32px;
				font-weight: bold;
				font-size: 15px;
			}
			
			&-cont{
				display: flex;
				margin-top: 8px;
				flex-wrap: wrap;
				.scc-oc-item{
					width: 25%;
					text-align: center;
					font-size: 13px;
					margin-bottom: 8px;
					
					img{
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		
		.scc-order:hover,.ssc-renzhen:hover{
			border: 1px dashed #0080FF;
		}
		
		.scc-acitve{
			border: 1px dashed #0080FF;
		}
	
		.scc-data-cont{
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			.scc-dc-item{
				width: 33%;
				text-align: center;
				line-height: 24px;
			}
		}
	}
	
	.ssc-right{
		width: 400px;
		height: 812px;
		border: 1px solid #e6e6e6;
		margin-left: 20px;
		padding:0 16px;
		overflow: hidden;
		overflow-y: auto;
		cursor: pointer;
		
		.ssc-ri-item{
			margin-bottom: 16px;
			border:1px solid #f4f4f4;
			padding: 12px;
			.el-form-item{
				margin-bottom: 4px;
				.el-input__inner,.el-input-group{
					width: 100%;
				}
			}
		}
		
		.scc-right-tag{
			font-size: 12px;
			position: absolute;
			margin-left:352px;
			z-index: 99;
			div{
				padding: 2px;
				background: #dedede;
				color: #333333;
				width: 60px;
				margin-bottom: 4px;
				text-align: center;
			}
			
			.scc-rt-active{
				background: #0080FF;
				color: #fff;
			}
		}
	}
}
</style>
